<template>
	<div>
		<div class="box box_mm">
			<div class="box_main">
				<div
					style="height: 75vh;overflow-y: auto;padding-right: 0.5rem;padding-bottom: 40px;box-sizing: border-box;">
					<el-form :model="addnewinfo" :rules="addnewinfo_rules" ref="addnewinfo" label-width="100px"
						label-position="left" class="demo-ruleForm" style="padding-left: 20px;">
						<el-form-item label="上传附件" required style="display: flex;align-items: center;">
							<el-upload
							  class="upload-demo"
							  :action="baseurl+'/api/login/upload'" :headers="{
							  		token:$store.state.userinfo.token
							  	}"
							  :on-success="handleAvatarSuccess"
							  :on-remove="handleChange"
							  :file-list="fileList"
							  :on-preview="preview"
							  :limit="1">
							  <el-button size="small" type="primary">点击上传</el-button>
							</el-upload>
						</el-form-item>
						<!-- <el-form-item label="是否拟办" required style="display: flex;align-items: center;">
							<el-select v-model="addnewinfo.sflb" placeholder="请选择">
							    <el-option label="是" :value="1"></el-option>
								<el-option label="否" :value="2"></el-option>
							  </el-select>
						</el-form-item> -->
						<el-form-item label="文单" prop="lydw" style="display: flex;align-items: flex-start;">
							<div class="mytabble" style="width: 60vw;">
								<div class="innerbox">
									<div class="title">
										政协西宁市委员办公室公文处理单
									</div>
									<div class="tablebox">
										<div class="line">
											<div class="namebox">
												<span>来文单位</span>					
											</div>
											<div class="value" style="width: 51%;">
												<el-input v-model="addnewinfo.lydw" placeholder="请输入"></el-input>
											</div>
											<div class="namebox">
												<span>收文日期</span>					
											</div>
											<div class="value" style="width: 25%;">
												<el-date-picker
												  v-model="addnewinfo.swrq"
												  type="date"
												  value-format="yyyy-MM-dd"
												  placeholder="选择日期">
												</el-date-picker>
											</div>
										</div>
										<div class="line">
											<div class="namebox">
												<span>份数</span>					
											</div>
											<div class="value" style="width: 12%;">
												<el-input v-model="addnewinfo.fen" type="number"></el-input>
											</div>
											<div class="namebox" style="width: 13.6%;">
												来文字号
											</div>
											<div class="value" style="width: 12%;border-right: none;">
												<el-input v-model="addnewinfo.lwzh"></el-input>
											</div>
											<div class="namebox" style="width: 13.4%;">
												收文字号
											</div>
											<div class="value" style="width: 12.0%;border-right: none;">
												<el-input v-model="addnewinfo.swzh"></el-input>
											</div>
											<div class="namebox" style="width: 10%;">
												密级
											</div>
											<div class="value" style="width: 15%;">
												<!-- <el-select v-model="addnewinfo.mj" placeholder="请选择">
												    <el-option label="无" value="无"></el-option>
													<el-option label="机密" value="机密"></el-option>
													<el-option label="秘密" value="秘密"></el-option>
													<el-option label="最高机密" value="最高机密"></el-option>
												  </el-select> -->
												公开
											</div>
										</div>
										<div class="line" style="height: 75px;">
											<div class="namebox" style="width: 18%;">
												<span>文件标题</span>					
											</div>
											<div class="value" style="width: 82%;border-right: 1px solid #333;">
												<el-input v-model="addnewinfo.name" placeholder="请输入文件标题"></el-input>
											</div>
										</div>
										<div class="line" style="height: 120px;">
											<div class="namebox" style="width: 18%;">
												<span>拟办意见</span>					
											</div>
											<div class="value" style="width: 82%;border-right: 1px solid #333;">
												<el-input
												  type="textarea"
												  :rows="4"
												  placeholder="请输入拟办意见"
												  v-model="addnewinfo.nbyj">
												</el-input>					
											</div>
										</div>
										<div class="line" style="height: 200px;">
											<div class="namebox" style="width: 18%;">
												<span>领导批示</span>					
											</div>
											<div class="value" style="width: 82%;border-right: 1px solid #333;">
												<span>
													
												</span>					
											</div>
										</div>
										<div class="line" style="height: 200px;">
											<div class="namebox" style="width: 18%;">
												<span>办理结果</span>					
											</div>
											<div class="value" style="width: 82%;border-right: 1px solid #333;">
												<span>
													
												</span>					
											</div>
										</div>
									</div>
								</div>
							</div>
						</el-form-item>						
						<!-- <el-form-item label="正文" required>
							<div style="border: 1px solid #ccc;width: 100%;width: 60vw;">
								<Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig"
									mode="default" />
								<Editor style="height: 500px; overflow-y: hidden;font-size: 16px;" v-model="addnewinfo.zhengwen"
									:defaultConfig="editorConfig" mode="default" @onCreated="onCreated" />
							</div>
						</el-form-item> -->
						<el-form-item label="紧急程度" required style="display: flex;align-items: center;">
							<el-select v-model="addnewinfo.jjcd" placeholder="请选择">
							    <el-option label="平急" value="平急"></el-option>
								<el-option label="加急" value="加急"></el-option>
								<el-option label="特急" value="特急"></el-option>
								<el-option label="特提" value="特提"></el-option>
							  </el-select>
						</el-form-item>						
						
						<el-form-item label="拟办人" required>
							<div style="display: flex;align-items: center;">
								<el-button type="primary" size="small" @click="select(1)">选择</el-button>								
							</div>
							<div>
								<span style="color: #999;">已选人员：</span>{{user_name}}
							</div>
						</el-form-item>
						<!-- <el-form-item label="委领导" required v-show="addnewinfo.sflb==2">
							<div style="display: flex;align-items: center;">
								<el-button type="primary" size="small" @click="select(2)">选择</el-button>								
							</div>
							<div>
								<span style="color: #999;">已选人员：</span>{{user_name2}}
							</div>
						</el-form-item>
						<el-form-item label="交办人" required v-show="addnewinfo.sflb==2">
							<div style="display: flex;align-items: center;">
								<el-button type="primary" size="small" @click="select(3)">选择</el-button>								
							</div>
							<div>
								<span style="color: #999;">已选人员：</span>{{user_name3}}
							</div>
						</el-form-item> -->
					</el-form>

				</div>

				<div style="display: flex;align-content: center;margin-top: 30px;">
					<el-button @click="setaddnew">取 消</el-button>
					<el-button type="warning" @click="submitForm(1)">保 存</el-button>
					<el-button type="primary" @click="submitForm(2)">提 交</el-button>
				</div>
			</div>
		</div>
		
		
		
		<selectuser ref="myselectuser" @selectedry="selectedry"></selectuser>
	</div>

</template>

<script>
	import {
		Message
	} from 'element-ui'
	import {
		Editor,
		Toolbar
	} from '@wangeditor/editor-for-vue'
	import selectuser from '@/components/select_user.vue'
	export default {
		components: {
			selectuser,
			Editor,
			Toolbar
		},
		data() {
			return {				
				baseurl: "",
				addnewinfo: {
					lydw:'',
					swrq:'',
					fen:1,
					lwzh:'',
					swzh:'',
					mj:'公开',
					sflb:1,//1拟办 2 直接批示
					name:'',
					jjcd:'平急',
					fj:'',
					ybuser_id:'',//拟办人
					wpsuser_id:'',//二次批示人，如果是拟办 只选拟办人，如果不是拟办，需要选择二次批示人
					jbuser_id:"",//交办人
					state:1,
					nbyj:"",
					zhengwen:""
				},
				addnewinfo_rules: {
					lydw: [{
						required: true,
						message: '请录入信息',
						trigger: 'blur'
					}]
				},				
				user_id: '',//拟办人
				user_name: '',//拟办人
				
				user_id2: '',//二次批示人
				user_name2: '',//二次批示人
				
				user_id3: '',//交办人
				user_name3: '',//交办人
				
				select_type:0,//1拟办人 2批示人 3交办人
				
				fileList:[],
				
				//富文本相关
				editor: null,
				toolbarConfig: {},
				editorConfig: {
					placeholder: '请输入内容...',
					MENU_CONF: {
						uploadImage: {
							server: this.$URL + '/api/login/upload',
							fieldName: 'file',
							// 单个文件的最大体积限制，默认为 2M
							maximgSize: 10 * 1024 * 1024, // 10M
							// 最多可上传几个文件，默认为 100
							maxNumberOfimgs: 100,
							// 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
							allowedimgTypes: [],
							// 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
							meta: {
				
								// otherKey: 'yyy'
								// img:''
							},
							// 将 meta 拼接到 url 参数中，默认 false
							metaWithUrl: false,
				
							// 自定义增加 http  header
							headers: {
								token: this.$store.state.userinfo.token
								// otherKey: 'xxx'
							},
				
							// 跨域是否传递 cookie ，默认为 false
							withCredentials: false,
				
							// 超时时间，默认为 10 秒
							timeout: 10 * 1000, //10 秒
				
							// 上传前
							onBeforeUpload(imgs) {
								Message({
									message: '图片正在上传中,请耐心等待',
									grouping: true,
									duration: 0,
									customClass: 'uploadTip',
									iconClass: 'el-icon-loading',
									showClose: true
								});
								return imgs;
							},
							// 自定义插入图片
							customInsert(res, insertFn) {
								// 因为自定义插入导致onSuccess与onFailed回调函数不起作用,自己手动处理
								// 先关闭等待的ElMessage
								Message.closeAll();
				
								if (res.code === 200) {
									Message.success({
										message: "图片上传成功",
										grouping: true,
									});
								} else {
									Message.error({
										message: "图片上传失败，请重新尝试",
										grouping: true,
									});
								}
								// 从 res 中找到 url alt href ，然后插入图片
								insertFn(res.fullurl);
								// console.log(res, "res.data")
							},
				
							// 单个文件上传成功之后
							onSuccess(img, res) {
								console.log(`${img.name} 上传成功`, res);
							},
				
							// 单个文件上传失败
							onFailed(img, res) {
								console.log(`${img.name} 上传失败`, res);
							},
				
							// 上传进度的回调函数
							onProgress(progress) {
								console.log('progress', progress);
								// progress 是 0-100 的数字
							},
				
							// 上传错误，或者触发 timeout 超时
							onError(img, err, res) {
								console.log(`${img.name} 上传出错`, err, res);
							}
						},
				
						// 上传视频
						uploadVideo: {
							server: this.$URL + '/api/login/upload',
							fieldName: "file",
							// 单个文件的最大体积限制，默认为 10M
							maxFileSize: 500 * 1024 * 1024, // 500M
							// 最多可上传几个文件，默认为 5
							maxNumberOfFiles: 3,
							// 选择文件时的类型限制，默认为 ['video/*'] 。如不想限制，则设置为 []
							allowedFileTypes: ["video/*"],
							// 将 meta 拼接到 url 参数中，默认 false
							metaWithUrl: false,
							// 自定义增加 http  header
							headers: {
								token: this.$store.state.userinfo.token
								// otherKey: 'xxx'
							},
							// 跨域是否传递 cookie ，默认为 false
							withCredentials: false,
							// 超时时间，默认为 30 秒
							timeout: 30 * 1000, // 15 秒
							onBeforeUpload(imgs) {
								Message({
									message: '正在上传中,请耐心等待',
									grouping: true,
									duration: 0,
									customClass: 'uploadTip',
									iconClass: 'el-icon-loading',
									showClose: true
								});
								return imgs;
							},
							// 自定义插入图片
							customInsert(res, insertFn) {
								// 因为自定义插入导致onSuccess与onFailed回调函数不起作用,自己手动处理
								// 先关闭等待的ElMessage
								Message.closeAll();
				
								if (res.code === 200) {
									Message.success({
										message: "视频上传成功",
										grouping: true,
									});
								} else {
									Message.error({
										message: "上传失败，请重新尝试",
										grouping: true,
									});
								}
								// 从 res 中找到 url alt href ，然后插入图片
								insertFn(res.fullurl);
								// console.log(res, "res.data")
							},
							// 上传进度的回调函数
							onProgress(progress) {
								console.log("progress", progress);
							},
							// 单个文件上传成功之后
							onSuccess(file, res) {
								console.log(`${file.name} 上传成功`, res);
							},
							// 单个文件上传失败
							onFailed(file, res) {
								console.log(`${file.name} 上传失败`, res);
							},
							// 上传错误，或者触发 timeout 超时
							onError(file, err, res) {
								console.log(`${file.name} 上传出错`, err, res);
							}
						}
					}
				},
			}
		},
		created() {			
			this.baseurl = this.$URL
			this.$post({
				url: '/api/matter/details',
				params: {
					id:this.$route.query.id
				}
			}).then((res) => {
				this.addnewinfo = res
				this.user_id = res.ybuser_id
				this.user_name = res.ybuser_name
				this.user_id2 = res.wpsuser_id
				this.user_name2 = res.wpsuser_name
				this.user_id3 = res.jbuser_id
				this.user_name3 = res.jbuser_name
				// if(res.csr.length>=1){
				// 	this.user_id3 = res.csr.map((item)=>{return item.id})
				// 	this.user_id3 = this.user_id3.join(',')
				// 	this.user_name3 = res.csr.map((item)=>{return item.name})
				// 	this.user_name3 = this.user_name3.join(',')
				// }
				
				let obj ={
					name:res.fj[0].filename,
					url:res.fj[0].fullurl,
					id:res.fj[0].id
				}
				this.fileList.push(obj)
			})
			
		},
		methods: {
			onCreated(editor) {
				this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
			},
			preview(file){
				window.open(file.url,"_blank")
			},
			select(i){
				this.select_type = i
				let cxidlist = []
				let cxnamelist =[]
				if(i==1){
					cxidlist = this.user_id?this.user_id.split(','):[]
					cxnamelist = this.user_name?this.user_name.split(','):[]
					this.$refs.myselectuser.isdanxuan = true
				}
				if(i==2){
					cxidlist = this.user_id2?this.user_id2.split(','):[]
					cxnamelist = this.user_name2?this.user_name2.split(','):[]
					this.$refs.myselectuser.isdanxuan = true
				}
				if(i==3){
					cxidlist = this.user_id3?this.user_id3.split(','):[]
					cxnamelist = this.user_name3?this.user_name3.split(','):[]
					this.$refs.myselectuser.isdanxuan = false
				}
				let list = []
				if(cxidlist.length>0){
					cxidlist.forEach((item,index)=>{
						let obj={
							name:cxnamelist[index],
							id:Number(item)
						}
						list.push(obj)
					})
				}				
				this.$refs.myselectuser.selectuser = list.length>0?list:[]
				this.$refs.myselectuser.dialogVisible = true
			},
			selectedry(e){
				if(this.select_type==1){
					this.user_id = e.map((item)=>{ return item.id })
					this.user_id = this.user_id.join(',')
					this.user_name = e.map((item)=>{ return item.name })
					this.user_name = this.user_name.join(',')
				}
				if(this.select_type==2){
					this.user_id2 = e.map((item)=>{ return item.id })
					this.user_id2 = this.user_id2.join(',')
					this.user_name2 = e.map((item)=>{ return item.name })
					this.user_name2 = this.user_name2.join(',')
				}
				if(this.select_type==3){
					this.user_id3 = e.map((item)=>{ return item.id })
					this.user_id3 = this.user_id3.join(',')
					this.user_name3 = e.map((item)=>{ return item.name })
					this.user_name3 = this.user_name3.join(',')
				}
				
			},
			submitForm(iii) {//iii 1保存 2提交
				this.addnewinfo.state = iii
				// if(!this.addnewinfo.zhengwen||this.addnewinfo.zhengwen=='<p><br></p>'){
				// 	this.$message.warning('请填写正文')
				// 	return
				// }
				if(this.fileList.length<=0){
					this.$message.warning('请先上传附件')
					return
				}
				this.addnewinfo.fj = this.fileList[0].id
				
				if(!this.user_id){
					this.$message.warning('请选择拟办人')
					return
				}
				// if(this.addnewinfo.sflb==2&&!this.user_id2){
				// 	this.$message.warning('请选择批示人')
				// 	return
				// }
				// if(this.addnewinfo.sflb==2&&!this.user_id3){
				// 	this.$message.warning('请选择交办人')
				// 	return
				// }
				this.addnewinfo.ybuser_id = this.user_id
				this.addnewinfo.wpsuser_id = ''
				this.addnewinfo.jbuser_id = ''
				// if(this.addnewinfo.sflb==2){
				// 	this.addnewinfo.wpsuser_id = this.user_id2
				// 	this.addnewinfo.jbuser_id = this.user_id3
				// 	this.addnewinfo.ybuser_id = ''
				// }
				this.$refs.addnewinfo.validate((valid) => {
					if (valid) {						
						this.$post({
							url: '/api/matter/edit',
							params: this.addnewinfo
						}).then((res) => {
							this.$message.success('操作成功')
							this.$router.push('/system/get_file/caogao')
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				})
			},
			handleAvatarSuccess(res, file) {
				this.addnewinfo.name = res.file_name.slice(0,-4)
				let obj ={
					name:res.file_name,
					url:res.fullurl,
					id:res.id
				}
				this.fileList.push(obj)
			},
			handleChange(file, fileList) {
				this.fileList = fileList			    
			},
			setaddnew() {
				this.$router.go(-1)
			},
			setdate(){
				var today = new Date();
				var year = today.getFullYear();
				var month = today.getMonth() + 1;
				var day = today.getDate();
				
				if(month < 10){
				    month = "0" + month;
				}
				
				if(day < 10){
				    day = "0" + day;
				}
				
				this.addnewinfo.swrq = year + "-" + month + "-" + day;
				this.addnewinfo.lwzh = year + "" + month + "" + day;
				this.addnewinfo.swzh = year + "" + month + "" + day;
			}
		}
	}
</script>

<style lang="scss" scoped>
	.box_main {
		background-color: #fff;
		padding: 15px 55px;
		width: 100%;
		box-sizing: border-box;
		min-height: 100%;
	}

	.search_box {
		display: flex;
		align-items: center;
		font-size: 0.16rem;

		.search_item {
			display: flex;
			align-items: center;
			margin-right: 0.5rem;

		}
	}

	.box_cont {
		margin-top: 20px;
	}

	.btn_list {
		display: flex;
		align-items: center;
	}

	.tc_title {
		font-size: 0.18rem;
		font-weight: bold;
		color: #333;
		position: relative;
		padding-left: 20px;
		margin-bottom: 20px;
	}

	.tc_title:after {
		content: '';
		position: absolute;
		height: 100%;
		width: 4px;
		left: 0px;
		background-color: rgb(26, 144, 253);
	}

	::v-deep td {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell>.cell {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell.is-leaf {
		background-color: rgb(242, 242, 242);
		color: #777;
	}

	::v-deep .el-table th.el-table__cell.is-leaf,
	.el-table td.el-table__cell {
		border-bottom: none !important;
	}

	::v-deep .el-table td.el-table__cell {
		border-bottom: none !important;
	}

	::v-deep .el-table {
		margin-top: 10px;
		border-left: none !important;
	}

	::v-deep .el-table__fixed {
		border-bottom: none;
	}

	::v-deep .el-table::before {
		background-color: #fff !important;
	}

	::v-deep .el-table__fixed::before {
		background-color: #fff !important;
	}

	::v-deep .el-dialog__header {
		display: flex !important;
	}

	::v-deep .el-dialog__footer span {
		display: flex !important;
		justify-content: space-around;
		width: 100%;
	}

	::v-deep .el-form-item__content {
		display: flex;
		flex-direction: column;
	}

	::v-deep .el-form-item__content {
		margin-left: 0 !important;
	}

	::v-deep .el-table__fixed-right::before {
		background-color: #fff !important;
	}

	.item_class {
		display: flex;
		align-items: center;
		width: 100%;
		box-sizing: border-box;

		.el-form-item {
			margin-right: 150px;
		}
	}

	::v-deep.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
	.innerbox{
		width: 100%;
		background-color: #fff;
		padding: 20px 10px;
	}
	.title{
		font-size: 22px;
		color: #333;
		width: 100%;
		text-align: center;
	}
	.line{
		display: flex;
		align-items: center;
		height: 50px;
		width: 100%;
	}
	.namebox{
		height: 100%;
		width: 12%;
		border: 1px solid #333;
		padding: 7px;
		text-align: center;
		border-right: none;
		border-bottom: none;
		display: flex;
		align-items: center;
		justify-content: center;
		span{
			width: 100%;
		}
	}
	.namebox:nth-last-child(1){
		border-right: 1px solid #333;
	}
	.value{
		height: 100%;
		width: 35%;
		padding:7px;
		text-align: center;
		border: 1px solid #333;
		word-wrap: break-word;
		display: flex;
		align-items: center;
		justify-content: center;
		border-bottom: none;
		span{
			width: 100%;
			
		}
	}
	.value:nth-child(2){
		border-right: none;
	}
	.line:nth-last-child(1){
		border-bottom: 1px solid #333;
	}
	.btm_btn{
		position: fixed;
		bottom: 0;
		width: 100vw;
		left: 0;
		padding: 10px 20px;
		background-color: #fff;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.tablebox{
		margin-top: 15px;
	}
	
	::v-deep .mytabble .el-input__inner{
		border: none;
	}
</style>